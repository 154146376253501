
<template>
<div class="container">
    <div class="row justify-content-center pt-5">
        <div class="col-md-8 col-lg-6">
            <div class="card">
                <div class="card-body p-4">
                    <a class="d-flex justify-content-center" :href="$store.state.domain">
                        <img width="120" :src="`${$store.state.domain}/images/logos/logo-dark.svg`" :alt="$store.state.appName" class="navbar-brand-img">
                    </a>
                    <div class="text-center m-auto">
                        <div class="auth-logo mb-3">
                            <h3 class="text-primary language-auth-title d-flex align-items-center">
                                <span class="">Login</span>
                            </h3>
                        </div>
                        <!-- <p class="text-muted mb-4 mt-3">Enter your email address and password to login.</p> -->
                    </div>

                    <form action="#">
                        <div class="form-group mb-3">
                            <label for="emailaddress">Email address</label>
                            <input v-model="data.email" v-validate="{ required: true, email: true }" name="emailaddress" :class="{ 'is-invalid': errors.has('emailaddress') }" class="form-control" type="email" id="emailaddress" placeholder="Enter your email" />
                        </div>

                        <div class="form-group mb-3">
                            <label for="password">Password</label>
                            <div class="input-group input-group-merge">
                                <input v-validate="'required|max:50'"
                                v-model="data.password" name="password"
                                :class="{ 'is-invalid': errors.has('password') }" :type="passwordType" id="password"
                                 class="form-control border-right-0" placeholder="Enter your password" />
                                <div class="input-group-append " style="cursor: pointer;" @click="passwordVisibility()">
                                    <div class="input-group-text bg-transparent border-left-0">
                                        <span class="password-eye"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <div class="custom-control custom-checkbox">
                                <input v-model="data.remember" type="checkbox" class="custom-control-input" id="checkbox-signin" checked />
                                <label class="custom-control-label" for="checkbox-signin">Remember me</label>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <button :disabled="!validateForm" @click.prevent="submitData()" class="btn btn-block btn-primary" type="button">Log In</button>
                        </div>
                    </form>

                </div>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</div>

<!-- end row -->
</template>

<script>
export default {
  data() {
    return {
      data: {
        email: '',
        password: '',
        role: 'admin',
        remember: true
      },
      passwordType: "password",

    }
  },
  computed: {
    validateForm() {
      return this.data.email != '' && this.data.password != '';
    },
  },
  methods: {
    passwordVisibility(){
        this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        this.pageloader();
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$http.post(`${this.$store.state.domain}/api/login`, formData)
        .then((response)=> {
          this.pageloader("stop");
          if(response.data.success){
            this.resetForm()
            const expirationTime = new Date( new Date().getTime() + +response.data.expiresIn * 1000);
            const config = {
              role: response.data.role,
              token: response.data.accessToken,
              tokenExpirationDate: expirationTime.toISOString(),
              user: response.data.user,
            }
            const encryptedValue = btoa(escape(JSON.stringify(config)));
            localStorage.setItem(this.$store.state.localUserKey, encryptedValue);
            window.location = "/";
          }else if(response.data.errors){
             response.data.errors.forEach((error) => { this.alertError(error)})
          }else{
            this.alertError(response.data.error)
          }
        }).catch(err => { console.error(err) })
      });
    },
    resetForm(){
      this.data = {
        email: '',
        password: '',
        role: 'admin',
        remember: true
      }
      this.$validator.reset()
    },
   },
  }
</script>
